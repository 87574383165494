// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: medium; // Breakpoint – switch to Desktop version

$desktopHover: false; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em 0;
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $primary;
	transition: all 300ms ease-in-out;
}
%buttonActive {
	// Link Active
	color: $primary;
	font-weight:500 !important;
	transition: all 300ms ease-in-out;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-down);
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								@extend .h5;
								padding: 1em 1rem 1rem 1.5rem;
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}

.fixedinfo{
	display:none;
	@include breakpoint(large){
		display:block;
		background:$primary;
		width:100%;
		color:$light;
		position: fixed;
		padding:1rem;
		font-size:14px;
		top:0;
		z-index:999;
		a{
			color:$light;
			text-decoration:none;
			&:hover{
				text-decoration: underline;
			}
		}
		.right{
			text-align: right;
		}
		span.phone{
			margin-right:2rem;
			&:before{
				font-family: $iconFont;
				content: map-get($iconMap, phone);
				margin-right: .5rem;
			}
		}
		a.escape.email{
			&:before{
				font-family: $iconFont;
				content: map-get($iconMap, envelope);
				margin-right: .5rem;
			}
		}
	}
	@include breakpoint(giant){
		font-size:16px;
	}
	@include breakpoint(huge){
		font-size:18px;
	}

	.openinghours{
		&:before{
			font-family: $iconFont;
			content: map-get($iconMap, clock);
			margin-right: .5rem;
		}
	}
}
// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#pageNavi {
		@include breakpoint(large){
			top: 3.3rem;
		}
		
			.branding {
				display: block;
				width: 11rem;
				height: auto;
				position: relative;
				z-index: 1;
				top:0;
				@include breakpoint(large){
					padding-top: .3rem;
					width: 12rem;
				}
			}

			picture {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 0;
				width: 100%;
				height: auto;
			}
		nav {
			ul {
				&.navi {
					padding: .7rem 0;
					font-size:16px;
					font-weight: 300;
					@include breakpoint(large){
						font-size:18px;
						padding: 1rem 0;
					}
					@include breakpoint(giant){
						font-size:20px;
						padding: 1rem 0;
					}
					li {
						&.impressum, .datenschutzerklaerung{
							display:none;
						}
						&.hasSub {
							
							span{
								margin-left: 1.5rem;
							}

							.sub {
								li{
									margin-left: 1.5rem;
									@include breakpoint(medium){
										border-top: 1px solid $primary;
										margin:0;
									}
									a{
										padding:.6rem 2rem .6rem 0;
									}
								}
							}
						}
						&.nav-back {
							&:before{
								width:100%;
							}
						}
					}
				}
			}
		}
	}
}
#pageNavi nav ul.navi li.hasSub.active > span{
	margin-left: 1.5rem;
}
// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($base-gap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
	span.phone{
			margin-right:2rem;
			&:before{
				font-family: $iconFont;
				content: map-get($iconMap, phone);
				margin-right: .5rem;
			}
		}
}

#pageNavi nav ul.navi li a{
	margin-left:1.5rem;
}

