// * Converts a pixel value to em, relative to the body font size (or any other value).
// *
// * Usage:
// * font-size:em(12px) (if based on body font size)
// * font-size:em(12px, 18px) (if based on a parent with a relativ font size of 18px)
// *
// * @param  {string} $to-size   Target pixel value
// * @param  {string} $from-size: $base-font-size (optional) Font size of the parent
// * @return {string}           Calculated em value
@function em($to-size, $from-size: $base-font-size) {
  @if $to-size == 0px {
    @return 0;
  }
  @return $to-size / $from-size * 1em;
}

// * Converts a pixel value to rem, relative to the body font size (or any other value).
// *
// * Usage:
// * font-size:rem(12px) (if based on body font size)
// * font-size:rem(12px, 18px) (if based on a parent with a relativ font size of 18px)
// *
// * @param  {string} $to-size   Target pixel value
// * @param  {string} $from-size: $base-font-size (optional) Font size of the parent
// * @return {string}           Calculated rem value
@function rem($to-size, $from-size: $base-font-size) {
  @if $to-size == 0px {
    @return 0;
  }
  @return $to-size / $from-size * 1rem;
}

// * Calculates the percent-based body font size based on a pixel value.
// *
// * Usage:
// *
// * body {
// *     font-size:body-font-size();
// * }
// *
// * @type {string} $pixel-value The layout’s base font size
//
@function body-font-size($pixel-value: $base-font-size) {
  @return ($base-font-size / 1px) * 100 / 16#{"%"};
}

////
/// @font-face Mixin for SASS
/// Builds font-face declarations
/// @author [Jonathan Neal](https://github.com/jonathantneal)
/// @author [Chris Coggburn](mailto:chris@pixelandline.com)
////

/// String Replace Function
/// @group functions
/// @param $string
/// @param $search
/// @param $replace [""]
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}

/// SCSS @font-face mixin
/// @param $name - font-family
/// @param $path - URL to fonts
/// @param $weight [null] - font-weight
/// @param $style [null] - font-style
/// @param $exts [eot woff2 woff ttf svg] - font extensions to add to src
/// @example scss - Create a font face rule that applies to bold and italic text.
///   // SCSS
///   @include font-face(Samplino, Samplino);
///
///   // CSS Output:
///   @font-face {
///       font-family: "Samplino";
///       src:
///            url("fonts/Samplino.woff2") format("woff2"),
///            url("fonts/Samplino.woff") format("woff"),
///            url("fonts/Samplino.ttf") format("truetype"),
///            url("fonts/Samplino.svg#Samplino") format("svg");
///       }
///
/// @example scss - Create a font face rule that applies to bold and italic text.
///   // SCSS
///   @include font-face("Samplina Neue", SamplinaNeue, bold, italic);
///
///   // CSS Output
///   @font-face {
///       font-family: "Samplina Neue";
///       font-style: italic;
///       font-weight: bold;
///       src:
///            url("fonts/SamplinaNeue.woff2") format("woff2"),
///            url("fonts/SamplinaNeue.woff") format("woff"),
///            url("fonts/SamplinaNeue.ttf") format("truetype"),
///            url("fonts/SamplinaNeue.svg#Samplina_Neue") format("svg");
///       }
@mixin font-face($name, $file, $weight: null, $style: null, $type: woff) {
  $src: null;
  $extmods: (
    svg: "#" + str-replace($name, " ", "_")
  );
  $formats: (
    ttf: "truetype"
  );
  @each $ext in $type {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote("fonts/" + $file + "." + $extmod)) format(quote($format)),
      comma
    );
  }
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: swap;
  }
}
