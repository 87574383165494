.modalGallery{
	margin-bottom: 2rem;
}
// HEADER
//////////////////////////////
#pageHeader{
	margin-top:2.8rem;
	background:#e5e5e5;
	
	@include breakpoint(medium){
		background: url('/images/medium-bg-header.webp') no-repeat center / cover;
		height:35rem;
		width:auto;
		margin-top:1.8rem;
	}
	@include breakpoint(xxmedium){
		height:37.5rem;
	}
	@include breakpoint(large){
		background: url('/images/bg-header.webp') no-repeat center / cover;
		height:45rem;
		width:auto;
		margin-top:3.5rem;
	}

	.claim{
		text-align:center;
		font-size:42px;
		line-height: 45px;
		font-weight: 300;
		margin-top: 2.5rem;
		padding: 0 2rem;
		z-index:1;
		position: relative;
    	overflow: hidden;
		margin-bottom: 3rem;
		strong{
			font-weight:700;
		}
			@include breakpoint(tiny){
				font-size:52px;
				line-height: 55px;
			}
			@include breakpoint(small){
				font-size:58px;
				line-height: 70px;
			}
			@include breakpoint(medium){
				margin-top: 12rem;
			}
			@include breakpoint(xxmedium){
				padding: 0rem 9rem;
				top: 0;
			}
			@include breakpoint(large){
				margin-top: 16rem;
				padding: 0rem 5rem;
			}
			@include breakpoint(full){
				font-size:72px;
				line-height: 82px;
			}

		.containerheadliner{
			.schrifter{
				margin-bottom: 1rem;
				width:17rem;
				@include breakpoint(little){
					width:20rem;
				}
				@include breakpoint(medium){
					width:24rem;
				}
			}
		}
		
		.subtext{
			font-size:24px;
			line-height: 32px;
    		margin-bottom: 0.7rem;

			@include breakpoint(xxmedium){
				font-size:28px;
				line-height:38px;
				margin-bottom: 1rem;
			}
			@include breakpoint(large){
				font-size:36px;
				line-height:52px;
				padding: 0 5rem;
			}
			@include breakpoint(xxlarge){
				padding: 0 9rem;
			}
			@include breakpoint(huge){
				padding: 0 14rem;
			}
			
		}
		.line{
			height:1px;
			background: #6e6f70;
			width:35%;
			margin:0rem auto 0 auto;
		}
		.btns{
			margin:2rem auto 0 auto;
			display: block;
			justify-content: center;
			@include breakpoint(medium){
				display: flex;
			}
			.btn{
				margin-right: .5rem;
				margin-bottom: .5rem;
				width: 100%;
				@include breakpoint(medium){
					width: auto;
				}
			}
			.btnspecial{
				margin-right: .5rem;
				margin-bottom: .5rem;
				width: 100%;
				@include breakpoint(medium){
					width: auto;
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	overflow: hidden;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList) {
		@extend .styledList;
	}

	.googleMaps {
		@extend .videoContainer;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}

	.schwan {
			display:none;
		@include breakpoint(xxlarge){
			display:block;
			opacity:1;
			width: 20rem;
		    position: absolute;
		    right: 0;		
			top: 28rem;
			margin-top:0;
		}
		@include breakpoint(huge){
			top: 24rem;
			width: 24rem;
		}
	}

	.first-section{
		position: relative;
		
		@include breakpoint(medium){
			&:before {
				content: '';
				height: 100%;
				position: absolute;
				right: 50%;
				width: 90vw;
				top: 0;
			}
		}
		@include breakpoint(huge){
			&:before {
				right: 35%;
				width: 90vw;
			}
		}
		&.lazyLoaded {
			@include breakpoint(medium) {
				&:before {
					background: url('/images/layout/wassertropfen-small.png') no-repeat center / cover;  
				}
			}
			@include breakpoint(huge) {
				&:before {
					background: url('/images/layout/wassertropfen.jpg') no-repeat center / cover; 
				}
			}
		}
		.first-content{
			height: 100%;
			position: relative;
			background:#e5e5e5;
			padding:3rem 1rem;
			text-align:center;
			@include breakpoint(medium){
				background:none;
				text-align:left;
				padding: 4rem 2rem;
			}
			@include breakpoint(huge){
				padding: 0rem 2rem;
			}
			.btn{
				margin:0 auto;
				@include breakpoint(medium){
					margin:0;
				}
			}
			.content{
				span{
						font-size:22px;
						font-weight:500;
					}
				@include breakpoint(medium){
					padding: 4rem 2rem;
					span{
						font-size:24px;
						font-weight:500;
					}
				}
				@include breakpoint(huge){
					padding: 9rem 2rem;
				}
			}

			.btn:hover{
				color: $secondary;
			}
		}
	}
	.second-section{
		position: relative;
		
		@include breakpoint(medium){
			&:before {
				content: '';
				height: 100%;
				position: absolute;
				left: 50%;
				width: 90vw;
				top: 0;
			}
		}
		@include breakpoint(huge){
			
			&:before {
				left: 35%;
				width: 90vw;
			}
		}
		&.lazyLoaded {
			@include breakpoint(medium) {
				&:before {
					background: url('/images/layout/bilderrahmen-small.png') no-repeat center / cover; 
				}
			}
			@include breakpoint(huge) {
				&:before {
					background: url('/images/layout/bilderrahmen.jpg') no-repeat center / cover; 
				}
			}
		}
		.second-content{
			height: 100%;
			position: relative;
			background:#e5e5e5;
			padding:3rem 1rem;
			text-align:center;
			@include breakpoint(medium){
				background:none;
				text-align:left;
				padding: 4rem 2rem;
			}
			@include breakpoint(huge){
				padding: 0rem 2rem;
			}
			.btn{
				margin:0 auto;
				@include breakpoint(medium){
					margin:0;
				}
			}
			.content{
				span{
						font-size:22px;
						font-weight:500;
					}
				@include breakpoint(medium){
					padding: 4rem 2rem;
					span{
						font-size:24px;
						font-weight:500;
					}
				}
				@include breakpoint(huge){
					padding: 9rem 2rem;
				}
			}

			.btn:hover{
				color: $secondary;
			}
		}
	}
}

.responsiveBackground {
	@include responsiveImage('bg-header.jpg', (tiny, medium), true);
	padding:4em 1.2em;
	color: $light;

	&.lazyLoaded {
		background-size:cover
	}
}

// FOOTER
//////////////////////////////

#pageFooter {
	margin-top: 3rem;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover; 
	text-align: center;
	&.lazyLoaded{
		background-image: url('/images/layout/footer.png');
	}
	@include breakpoint(xxsmall){
		text-align:left;
	}

	.navi {	
		li{
			a.facebook{
					font-size:0px;

					&:after{
						content:'';
						background: url('/images/layout/facebook.png') no-repeat center / cover;
						width: 30px;
						height: 30px;
						display: block;
						margin: -1.5rem auto 0 auto;
					}
					&:hover{
						&:after{
							opacity:0.7;
							transition: all 300ms ease-in-out;
						}
					}
				}
			}
		@include breakpoint(xxsmall){
			li{
				a.facebook{
					&:after{
						margin: -1.5rem 0 0 0;
					}
				}
			}
		}
		@include breakpoint(xxlarge){
			display: flex;
			justify-content: center;

			li {
				margin-right: 1rem;

				a.facebook{
					&:after{
						margin: -1.5rem auto 0 auto;
					}
				}
			}
		}
		
	}
	p{
		color:$light !important;
	}
	
	.row{
		padding: 3rem 1rem 0rem 1rem;
		@include breakpoint(xxsmall){
			padding:3rem 1rem;
		}
		.col{
			padding: 0;
			margin-bottom: 2rem;
			@include breakpoint(xxsmall){
				margin-bottom: 0;
			}
			@include breakpoint(huge){
				margin-bottom: -1.5rem;
			}
			.siegel{
				width:45%;
				@include breakpoint(xxsmall){
					width:65%;
				}
				@include breakpoint(huge){
					width: 60%;
    				margin-bottom: 1rem;
				}

			}
			p{
				color:$light !important;
				font-size:16px;	
					@include breakpoint(huge){
						font-size:18px;						
					}
				span{
					font-size:20px;	
					@include breakpoint(huge){
						font-size:24px;						
					}
				}
				span.checker{
					font-size:16px;	
					&:before{
						font-family: $iconFont;
						content: map-get($iconMap, check);
						margin-left:.7rem;
						margin-right:.3rem;
					}
					@include breakpoint(huge){
						font-size:18px;							
					}
				}
				span.firstandlast{
					font-size:16px;	
					&:before{
						font-family: $iconFont;
						content: map-get($iconMap, check);
						margin-right:.3rem;
					}
					@include breakpoint(huge){
						font-size:18px;							
					}
				}
				&.address{
					font-size:16px;
				}
			}
			a{
				color:$light;
				font-size:16px;
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
			}
			hr{
				background:#9ecfce;
				margin:5rem 0 2rem 0;
			}
		}
	}
	
	.right{
		float:right;
		margin-top: 1rem;
		@include breakpoint(xxlarge){
			margin:0;
		}
	}
	iframe{
		height:20rem;
		margin-bottom: -1rem;
	}
}
.fixedinfomobil{
	@include breakpoint(large){
		display:none;		
	}
	.left{
		@include breakpoint(small){
			margin-top:1rem;	
		}
	}
	p{ 
		font-size: 16px;
	}

	a{
		padding-left: 1rem;
	}
}

.youtubeUeberUns{
	height:auto;
	min-height:20rem;
	width:100%;
}

body:not(.index){
	#pageHeader{
		background:#e5e5e5;
		height:13rem;
		@include breakpoint(small){
			height:11rem;
		}
		@include breakpoint(medium){
			height:15rem;
		}
		@include breakpoint(large){
			height:20rem;
			margin-top:2.8rem;
		}
		.claim{
			    margin-top: 4rem;
			@include breakpoint(medium){
				margin-top: 7.5rem;
				padding:0;
			}
			@include breakpoint(large){
				padding:0;
				margin-top:11.5rem;
			}
		}
		.subtext{
			font-size:20px;
			line-height: 32px;
    		margin-bottom: 0.7rem;

			@include breakpoint(xxmedium){
				font-size:24px;
				line-height:34px;
				margin-bottom: 1rem;
			}
			@include breakpoint(large){
				font-size:26px;
				line-height:36px;
			}
		}
		.btns,.containerheadliner{
			display:none;
		}
	}
	
	.headerpic{
		img{
			display:none;
		}
		height:15.5rem;
	}
	.schwan{
		display:none;
	}
}



// VIDEO
.video-cont {
	.icon{
		font-family: $main-font;
	}
	.video {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: block;
		z-index: 999999;
		width: 100%;
		display: none;



		.video-box {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 1rem;

			iframe {
				height: rem(300px);
				
				@include breakpoint(large) {
					height: rem(820px);
					width: 80%;
				}
			}
		}
	}

	label[for="video"] {
		display: block;
		cursor: pointer;

		span {
			background: $primary;
			color:$light;
			font-size:18px;
			font-weight:300;
			padding: 1rem 1.2rem;
			text-align: center;
			transition: all 300ms ease-in-out;

			&:before {
				color: $light;
				font-size: rem(30px);
			}
		}

		&:hover {
			span {
				background: darken($primary, 15%);
			}

			&:before {
				color: $primary;
			}
		}

		&:before {
			@extend .icon;
			@extend .icon-times;
			position: fixed;
			right: rem($base-gap);
			top: 3rem;
			color: $light;
			font-size: rem(30px);
			z-index: 9999999999;
			opacity: 0;
			visibility: hidden;
			display: none;
			transition: all 300ms ease-in-out;
		}


		&:after {
			@extend %animatedTransform;
			content: "";
			position: fixed;
			left: 0;
			top: 0;
			width: 200vw;
			height: 200vh;
			display: block;
			background-color: $dark;
			opacity: 0;
			visibility: hidden;
			z-index: 99999;
		}
	}
}

#video {
	display: none;

		&:checked {
		~ * .video,	~ .video {
			display: block;
		}

		~ label[for="video"] {
			&:after {
				opacity: 0.9;
				visibility: visible;
				pointer-events: auto;
			}

			&:before {
				opacity: 1;
				visibility: visible;
				display: block;
			}
		}
	}
}

.facebook{
	font-size:0px;
}

.my-slider, .swiper-wrapper{
	.slide{
		border:5px solid $border;
		margin:0 .3rem;
		height:21rem;
		padding: 3rem 1rem 2rem 1rem;
		display: flex;
	    flex-direction: column;
	    align-items: center;
		@include breakpoint(tiny){
			margin:0 1rem;
		}
		&:hover{
			background:$primary;
			transition: all 300ms ease-in-out;
			color:$light;
		}
	}
	img{
		border-radius: 100px;
		width: 11rem;	
		height: 11rem;
		height: auto;
		margin-bottom: 2rem;
		background-color: #9ecfce;
	}
	a{
		text-align:center;
		text-transform: uppercase;
		font-size:rem(18px);
		text-decoration: none;
		@include breakpoint(tiny){
			font-size:rem(22px);
		}
		@include breakpoint(medium){
			font-size:rem(22px);
		}
		@include breakpoint(giant){
			font-size:rem(24px);
		}
	}
}

body.cmsBackend{
	.my-slider{
		display:none;
	}
}

body.anfahrt{
	.footerframe{
		display:none;
	}
}

.tns-slide-active {
	img {
		@extend %lazyloading;
	}
}

// .youtube {
// 	width:100%!important; 
// 	height:40%!important; 
// }

.dreiD{
	height:300px;
}