// Relativ path to images folder (used in the image-url() function)
$image-url-path: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$base-font-size: 18px;

// Line height of main content (in px)
$base-line-height: 28px;

// Headline font sizes (in px)
$h1Size: 32px;
$h2Size: 28px;
$h3Size: 24px;
$h4Size: 22px;
$h5Size: 18px;
$h6Size: 16px;

// Font stacks: $main-font for simple content, $displayFont for headlines etc., $iconFont for icons
$main-font:    "Roboto", sans-serif;
$displayFont: "Roboto", sans-serif;
$iconFont:   "icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:   #068584; //türkis
$secondary: #EDE8DD;
$light:     #fff;
$medium:    #3c3c3c;
$dark:      #3c3c3c;
$border:    #e9e9e9; //light-grey
$alert:     #D85D00; //light-grey

// Horizontal padding left and right of grid columns
$base-gap: 18px;

// Grid row max-width
$row-max-width: 1400px;

// Grid columns
$column-count: 12;

// Standard border for your design
$base-border: 1px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bp-context: 16px;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideText-direction: "right";

$iconMap: (
    times: "×",
    bars: "≡",
    minus: "-",
    angle-right: "❯",
    plus: "+",
    angle-up: "↑",
    exclamation: "!",
    angle-down: "\e900",
    clock: "\e901",
    envelope: "\e902",
    phone: "\e903",
    check: "\e904",
);

// // EXAMPLE FOR USE WITH ICONFONT
// $iconMap: (
//     times: "\f00d",
//     bars: "\f0c9",
//     minus: "\f068",
//     angle-right: "\f054",
//     plus: "\f067",
//     angle-up: "\f077",
//     exclamation: "\f06a",
    
// );


/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bp-context), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
    )
*/
$grid-map: (
    tiny: (
        width:  em(480px, $bp-context)
    ),
    little: (
        width:  em(570px, $bp-context)
    ),
    small: (
        width:  em(620px, $bp-context),
        cols: (3,4,6,8,9)
    ),
    xxsmall: (
        width:  em(720px, $bp-context),
        cols: (3,4,6,8,9)
    ),
    medium: (
        width:  em(800px, $bp-context),
        cols: (3,4,5,6,8,9,12),
        prefix: (4,6,8),
        suffix: (4,6,8)
    ),
    xxmedium: (
        width:  em(900px, $bp-context),
    ),
    large: (
        width:  em(993px, $bp-context),
        cols: (3,4,5,6,7,8,9),
        prefix: (4,6,8),
    ),
    xxlarge: (
        width:  em(1120px, $bp-context),
        cols: (3,6,9),
    ),
    giant: (
        width:  em(1200px, $bp-context),
        cols: (6),
    ),
    xxgiant: (
        width:  em(1290px, $bp-context),
        cols: (6),
    ),
    huge: (
        width:  em(1364px, $bp-context),
        cols: (4,5,6,7,8),
        prefix: (4,6,8),
        suffix: (4,6,8)
    ),
    xxhuge: (
        width:  em(1480px, $bp-context)
    ),
    full: (
        width:  em(1520px, $bp-context)
    ),
    xxfull: (
        width:  em(1650px, $bp-context)
    ),
    monster: (
        width:  em(1800px, $bp-context)
    ),
    xxmonster: (
        width:  em(2100px, $bp-context)
    ),
    xxlmonster: (
        width:  em(2300px, $bp-context)
    )
);