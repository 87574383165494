// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

@include font-face(
 $name: "icomoon",
 $file: "icomoon",
 $weight: 400,
 $style: normal,
 $type: woff
);

@include font-face(
	$name: "Roboto", 
 	$file: "roboto-v30-latin", 
 	$weight: ("300", "300|italic", "400|italic", "400", "500", "500|italic", "700|italic", "700"), 
 	$type: all
);